// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-experience-experience-jsx": () => import("./../../../src/pages/experience/Experience.jsx" /* webpackChunkName: "component---src-pages-experience-experience-jsx" */),
  "component---src-pages-experience-index-jsx": () => import("./../../../src/pages/experience/index.jsx" /* webpackChunkName: "component---src-pages-experience-index-jsx" */),
  "component---src-pages-fun-facts-index-jsx": () => import("./../../../src/pages/funFacts/index.jsx" /* webpackChunkName: "component---src-pages-fun-facts-index-jsx" */),
  "component---src-pages-home-about-me-jsx": () => import("./../../../src/pages/home/AboutMe.jsx" /* webpackChunkName: "component---src-pages-home-about-me-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-portfolio-joj-index-jsx": () => import("./../../../src/pages/portfolio/joj/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-joj-index-jsx" */),
  "component---src-pages-portfolio-portfolio-jsx": () => import("./../../../src/pages/portfolio/Portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-portfolio-jsx" */)
}

